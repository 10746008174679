import React from 'react';
import FilterPopup from '../FilterPopup/FilterPopup';
import classNames from 'classnames';

import FilterPlain from '../FilterPlain/FilterPlain';
import { FieldDateInput, IconCalendar } from '../../../components';
import { stringifyDateToISO8601 } from '../../../util/dates';

import css from './BookingDateFilter.module.css';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { parse } from '../../../util/urlHelpers';
import { checkIfEventDateIsPossible } from '../../../util/booking';

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

const BookingDateFilter = props => {
  const {
    rootClassName,
    className,
    id,
    name,
    label,
    options,
    contentPlacementOffset,
    onSubmit,
    queryParamNames,
    schemaType,
    searchMode,
    intl,
    showAsPopup,
    icon,
    iconClassName,
    inputClassName,
    wrapperClassName,
    key,
    ...rest
  } = props;

  const classes = classNames(rootClassName, className);
  const queryParamName = getQueryParamName(queryParamNames);

  const location = useLocation();

  const { dates: date } = parse(location.search);
  const initialValues = date ? { dates: { date: new Date(date) } } : { dates: undefined };

  const handleSubmit = values => {
    const { dates } = values;
    if (!dates?.date) return;

    onSubmit({
      dates: stringifyDateToISO8601(dates?.date),
    });
  };

  return showAsPopup ? (
    <FilterPopup
      {...rest}
      className={classes}
      rootClassName={rootClassName}
      name={name}
      id={`${id}.popup`}
      showAsPopup
      contentPlacementOffset={contentPlacementOffset}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      keepDirtyOnReinitialize={false}
    >
      <div className={wrapperClassName}>
        <FieldDateInput
          name={queryParamName}
          id={`${id}.date`}
          className={css.input}
          customStyle={true}
          customInputIcon={<IconCalendar />}
          placeholderText={intl.formatMessage({ id: 'SearchPage.eventDatePlaceholder' })}
          isDayBlocked={day => !checkIfEventDateIsPossible(day)}
        />
      </div>
    </FilterPopup>
  ) : (
    <FilterPlain
      {...rest}
      className={className}
      rootClassName={rootClassName}
      label={label}
      id={`${id}.plain`}
      liveEdit
      onSubmit={handleSubmit}
      initialValues={initialValues}
      keepDirtyOnReinitialize={false}
    >
      <div className={classNames(wrapperClassName, css.root)}>
        <FieldDateInput
          name={queryParamName}
          id={`${id}.date`}
          className={css.input}
          customStyle={true}
          customInputIcon={<IconCalendar />}
          placeholderText={intl.formatMessage({ id: 'SearchPage.eventDatePlaceholder' })}
          isDayBlocked={day => !checkIfEventDateIsPossible(day)}
        />
      </div>
    </FilterPlain>
  );
};

export default BookingDateFilter;
